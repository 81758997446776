import Grid from "./Grid";
import ChartSeries from "./ChartSeries";
import ErrorPointsPlot from "./ErrorPointsPlot";
import AxisLabels from "./AxisLabels";
import SideLabel from "./SideLabel";
import useSpectrumSide from "./hooks/useSpectrumSide";

const SpectrumSide = ({
  data,
  initialData,
  acDataPoints: initialAcDataPoints,
  bcDataPoints: initialBcDataPoints,
  errorPoints: initialErrorPoints,
  leftSide,
  rightSide,
  onChange,
  ...rest
}) => {
  const {
    svgAttribs,
    gridAttribs,
    dragActions,
    chartSeries,
    errorPoints,
    yAxisValuesSecondary,
  } = useSpectrumSide({
    data,
    initialData,
    acDataPoints: initialAcDataPoints,
    bcDataPoints: initialBcDataPoints,
    errorPoints: initialErrorPoints,
    leftSide,
    rightSide,
    onChange,
    ...rest,
  });
  return (
    <>
      <div style={{ position: "relative" }}>
        {rest?.size !== "small" && <AxisLabels onTop {...rest} />}
        {rest.labelsOnLeft && rest?.size !== "small" && (
          <>
            <AxisLabels onLeft {...rest} axisValues={yAxisValuesSecondary} />
            <SideLabel onLeft {...rest} />
          </>
        )}
        {rest.labelsOnRight && rest?.size !== "small" && (
          <>
            <AxisLabels onRight {...rest} axisValues={yAxisValuesSecondary} />
            <SideLabel onRight {...rest} />
          </>
        )}
        <svg {...svgAttribs} {...dragActions}>
          <Grid {...gridAttribs} {...rest} />
          {chartSeries.map((chartSeriesItem, idx) => {

            return (
              <ChartSeries
                key={chartSeriesItem?.id ? chartSeriesItem?.id : idx}
                {...chartSeriesItem}
                {...rest}
              />
            );
          })}
          <ErrorPointsPlot errorPoints={errorPoints} {...rest} />
        </svg>
      </div>
    </>
  );
};

export default SpectrumSide;
