import { useState, useEffect } from "react";
import { lateralityFrequencies } from "../chartConfig";

const useSpectrum = ({ spectrum, initialSpectrum, onChange, patchOnly }) => {
  const [rightSideAttribs, setRightSideAttribs] = useState({
    onChange,
    side: "right",
    initialData: initialSpectrum?.right,
  });

  const [leftSideAttribs, setLeftSideAttribs] = useState({
    onChange,
    side: "left",
    initialData: initialSpectrum?.left,
  });

  let initialLaterality = {};
  if (Array.isArray(initialSpectrum?.laterality)) {
    initialSpectrum?.laterality?.map(
      (item) => (initialLaterality[item.f] = item.v)
    );
  }
  const [laterality, setLaterality] = useState(
    initialLaterality != null ? initialLaterality : {}
  );
  let initialSelectivity = { right: "OK", both: "OK", left: "OK" };
  if (initialSpectrum?.left != null && initialSpectrum?.right != null) {
    if (
      initialSpectrum?.left?.blue_slashes == null &&
      initialSpectrum?.right?.blue_slashes == null
    ) {
      initialSelectivity = { right: "NT", both: "NT", left: "NT" };
    }
  }
  const [selectivity, setSelectivity] = useState(initialSelectivity);
  const [timeStamp, setTimeStamp] = useState();
  const [selectedSeries, setSelectedSeries] = useState();
  const [deleteMode, setDeleteMode] = useState();

  let showModeButtons = true;
  if (Array.isArray(patchOnly)) {
    showModeButtons = false
    if (patchOnly.indexOf("ac") > -1 && patchOnly.indexOf("bc") > -1) {
      showModeButtons = true
    }
  }

  const onSelectSeries = (series) => {
    setSelectedSeries((state) => {
      if (state !== series) return series;
      return undefined;
    });
  };

  const onClear = (event) => {
    event.preventDefault();
    setRightSideAttribs({
      onChange,
      side: "right",
      initialData: initialSpectrum,
    });
    setLeftSideAttribs({
      onChange,
      side: "left",
      initialData: initialSpectrum,
    });
    setLaterality(initialLaterality != null ? initialLaterality : {});
    setSelectivity(initialSelectivity);
    setTimeStamp(Date.now());
  };

  const handleKeyDown = (event) => {
    if (event?.key === "Control") setSelectedSeries("BC");
    if (event?.key === "Alt") setDeleteMode(true);
  };

  const handleKeyUp = (event) => {
    if (event?.key === "Control") setSelectedSeries();
    if (event?.key === "Alt") setDeleteMode();
  };

  const handleSelectivityClick = ({ side, value }) => {
    if (Array.isArray(patchOnly)) {
      if (patchOnly.indexOf("selectivity") < 0) return false;
    }
    setSelectivity((state) => {
      if (side !== "both") {
        if (state[side] === "NT") {
          const newState = { ...state, [side]: "OK" };
          if (
            (newState == null || newState?.right === "OK") &&
            (newState == null || newState?.left === "OK")
          ) {
            newState.both = "OK";
          }
          return newState;
        } else {
          return { ...state, [side]: "NT", both: "NT" };
        }
      } else {
        if (value === "OK") {
          return { right: "OK", both: "OK", left: "OK" };
        } else {
          let left = spectrum?.left?.blue_slashes == null ? "NT" : "OK";
          let right = spectrum?.right?.blue_slashes == null ? "NT" : "OK";
          const newState = { left, both: "NT", right };
          return newState;
        }
      }
    });
  };

  const selectivityInputs = ["right", "both", "left"].map((side) => ({
    key: side,
    side: side,
    value: selectivity[side] || "OK",
    handleClick: handleSelectivityClick,
  }));

  const handleLateralityClick = ({ f, value }) => {
    if (Array.isArray(patchOnly)) {
      if (patchOnly.indexOf("laterality") < 0) return false;
    }
    setLaterality((state) => {
      if (state[f] === value) {
        const newState = { ...state };
        delete newState[f];
        return newState;
      }
      return { ...state, [f]: value };
    });
  };

  const lateralityInputs = lateralityFrequencies.map((frequency) => ({
    key: frequency,
    f: frequency,
    value: laterality[frequency],
    caption: frequency,
    handleClick: handleLateralityClick,
  }));

  useEffect(() => {
    onChange({ selectivity });
    // eslint-disable-next-line
  }, [JSON.stringify(selectivity)]);

  useEffect(() => {
    onChange({ laterality });
    // eslint-disable-next-line
  }, [JSON.stringify(laterality)]);

  useEffect(() => {
    if (spectrum?.left?.blue_slashes != null) {
      handleSelectivityClick({ side: "both", value: "OK" });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(spectrum?.left?.blue_slashes)]);

  useEffect(() => {
    if (spectrum?.right?.blue_slashes != null) {
      handleSelectivityClick({ side: "both", value: "OK" });
    }
    // eslint-disable-next-line
  }, [JSON.stringify(spectrum?.right?.blue_slashes)]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
    // eslint-disable-next-line
  }, []);

  return {
    rightSideAttribs,
    leftSideAttribs,
    timeStamp,
    onClear,
    onSelectSeries,
    selectedSeries,
    deleteMode,
    setDeleteMode,
    lateralityInputs,
    selectivityInputs,
    showModeButtons,
  };
};

export default useSpectrum;
