import SpectrumSide from "./SpectrumSide";
import AxisLabels from "./AxisLabels";
import useSpectrum from "./hooks/useSpectrum";
import Button from "../../components/Button";
import SelectivityInput from "./SelectivityInput";
import LateralityInput from "./LateralityInput";
import { leftLabelWidth } from "./chartConfig";
import useLang from "../../hooks/useLang";

const Spectrum = ({ spectrum, initialSpectrum, onChange, patchOnly }) => {
  const {
    rightSideAttribs,
    leftSideAttribs,
    lateralityInputs,
    selectivityInputs,
    timeStamp,
    onClear,
    onSelectSeries,
    selectedSeries,
    deleteMode,
    setDeleteMode,
    showModeButtons,
  } = useSpectrum({ spectrum, initialSpectrum, onChange, patchOnly });
  const lang = useLang();

  return (
    <div>
      <div
        style={{
          overflow: "auto",
          marginBottom: "var(--padding-section-bottom)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              left: "0",
              right: "0",
              zIndex: "1",
            }}
          >
            {selectivityInputs?.map((selectivityAttribs) => (
              <SelectivityInput
                {...{ ...selectivityAttribs, spectrum, patchOnly }}
              />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              width: "fit-content",
            }}
          >
            <SpectrumSide
              key={`${timeStamp}-RIGHT`}
              labelsOnTop
              labelsOnLeft
              selectedSeries={selectedSeries}
              deleteMode={deleteMode}
              patchOnly={patchOnly}
              {...rightSideAttribs}
            />
            <div
              style={{
                position: "relative",
                width: `${leftLabelWidth}px`,
              }}
            >
              <AxisLabels onLeft labelsOnTop labelsOnLeft oddOnly noPadding />
            </div>
            <SpectrumSide
              key={`${timeStamp}-LEFT`}
              labelsOnTop
              labelsOnRight
              selectedSeries={selectedSeries}
              deleteMode={deleteMode}
              patchOnly={patchOnly}
              {...leftSideAttribs}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "var(--gap-base)",
              paddingTop: "var(--padding-section-header)",
              paddingBottom: "var(--padding-section-bottom)",
              justifyContent: "center",
            }}
          >
            {lateralityInputs?.map((lateralityAttribs) => (
              <LateralityInput {...lateralityAttribs} />
            ))}
          </div>
        </div>
      </div>
      {showModeButtons && (
        <div
          style={{
            display: "flex",
            gap: "var(--gap-base)",
            justifyContent: "center",
          }}
        >
          <Button onClick={onClear}>{lang("common.clear")}</Button>
          <Button
            pressed={selectedSeries === "AC"}
            onClick={(event) => {
              event.preventDefault();
              onSelectSeries("AC");
            }}
          >
            {lang("common.ac")}
          </Button>
          <Button
            pressed={selectedSeries === "BC"}
            onClick={(event) => {
              event.preventDefault();
              onSelectSeries("BC");
            }}
          >
            {lang("common.bc")}
          </Button>
          <Button
            pressed={deleteMode}
            onClick={(event) => {
              event.preventDefault();
              setDeleteMode((state) => !state);
            }}
          >
            {lang("common.delete_point")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Spectrum;
