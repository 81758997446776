import { usePatchSpectrumMutation } from "../spectraSlice";

const usePatchSpectrum = () => {
  const [patchMutation, mutationProps] = usePatchSpectrumMutation();

  const mutation = async ({ params, body: { spectrum } }) => {
    const lateralityFrequencies = Object.keys(spectrum?.laterality);
    const laterality = lateralityFrequencies.map((frequency) => ({
      f: frequency,
      v: spectrum?.laterality[frequency],
    }));
    let newBody = { ...spectrum, laterality };
    if (spectrum?.selectivity?.both === "NT") {
      newBody.left.blue_slashes = null;
      newBody.right.blue_slashes = null;
    }
    try {
      await patchMutation({
        params,
        body: newBody,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return [mutation, mutationProps];
};

export default usePatchSpectrum;
