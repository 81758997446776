import { useParams } from "react-router-dom";
import useGetSpectrum from "./useGetSpectrum";

const useSpectrumBlock = () => {
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum({ id: spectrumId });

  const items = [
    {
      id: "spectrum",
      type: "spectrum",
      data: spectrum?.data,
    },
  ];

  return items;
};

export const useSpectrumBlockPatchable = () => {
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum({ id: spectrumId });

  const items = [
    {
      id: "spectrum",
      type: "spectrumPatchable",
      data: spectrum?.data,
    },
  ];

  return items;
};

export default useSpectrumBlock;
