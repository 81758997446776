import useLang from "../../../hooks/useLang";
import { useParams } from "react-router-dom";
import useGetSpectrum from "./useGetSpectrum";
import {
  useDeleteSpectrumMutation,
  usePatchSpectrumNotesMutation,
  usePatchSpectrumNameMutation,
} from "../spectraSlice";
import useConfirmationModal from "../../forms/hooks/useConfirmationModal";
import useHasAccess from "../../../hooks/useHasAccess";
import { useSpectrumBlockPatchable } from "./useSpectrumBlock";
import usePatchSpectrum from "./usePatchSpectrum";
import useSpectrumFeaturesBlock from "./useSpectrumFeaturesBlock";
import usePatchSpectrumFeatures from "./usePatchSpectrumFeatures";
import useSpectrumTagsBlock from "./useSpectrumTagsBlock";
import useNotesBlock from "./useNotesBlock";
import useNameBlock from "./useNameBlock";
import usePatchSpectrumTags from "./usePatchSpectrumTags";
import useModifierModal from "../../forms/hooks/useModifierModal";

const useSpectrumPage = () => {
  const hasAccess = useHasAccess({ apiCall: "spectra.id.GET" });
  const lang = useLang();
  const { spectrumId } = useParams();
  const spectrum = useGetSpectrum(
    { id: spectrumId },
    { skip: spectrumId == null || !hasAccess }
  );

  const { formActions: deleteFormActions } = useConfirmationModal({
    mutationHook: useDeleteSpectrumMutation,
    params: { id: spectrumId },
    navigateOnSuccess: () => "/spectra/",
  });

  const { formAttribs: editFormAttribs, formActions: editSpectrum } =
    useModifierModal({
      mutationHook: usePatchSpectrum,
      formConfigHook: useSpectrumBlockPatchable,
      params: { id: spectrumId },
    });

  const featuresBlockItems = useSpectrumFeaturesBlock();
  const { formAttribs: featuresFormAttribs, formActions: editFeatures } =
    useModifierModal({
      mutationHook: usePatchSpectrumFeatures,
      formConfigHook: useSpectrumFeaturesBlock,
    });
  const tagsBlockItems = useSpectrumTagsBlock();
  const { formAttribs: tagsFromAttribs, formActions: editTags } =
    useModifierModal({
      mutationHook: usePatchSpectrumTags,
      formConfigHook: useSpectrumTagsBlock,
    });
  const notesBlockItems = useNotesBlock();
  const { formAttribs: notesFromAttribs, formActions: editNotes } =
    useModifierModal({
      mutationHook: usePatchSpectrumNotesMutation,
      params: { id: spectrumId },
      formConfigHook: useNotesBlock,
    });
  const { formAttribs: nameFromAttribs, formActions: editName } =
    useModifierModal({
      mutationHook: usePatchSpectrumNameMutation,
      params: { id: spectrumId },
      formConfigHook: useNameBlock,
    });

  const participantSection = spectrum?.data?.participant
    ? {
        direction: "row",
        items: [
          {
            id: "iid",
            value: spectrum?.data?.participant?.iid,
          },
          {
            id: "year_born",
            value: spectrum?.data?.participant?.year_born,
          },
          {
            id: "gender",
            value: spectrum?.data?.participant?.gender,
          },
        ],
      }
    : {};

  const page = {
    hasAccess,
    header: {
      title:
        spectrum?.data?.name ||
        spectrum?.data?.id ||
        lang("spectra.example_loading"),
      lastEdit: spectrum?.data?.last_edit,
      buttons: [
        {
          caption: lang("captions.rename"),
          title: lang("spectra.edit_name"),
          type: "modifier-modal",
          mutation: "spectra.id.name.PATCH",
          formAttribs: nameFromAttribs,
          ...editName,
        },
        {
          caption: lang("common.delete"),
          type: "confirmation-modal",
          mutation: "spectra.id.DELETE",
          deemphasize: true,
          confirmationText: `${lang("common.delete")} ${spectrum?.data?.id}?`,
          ...deleteFormActions,
        },
      ],
    },
    articles: [
      {
        header: {
          buttons: {
            caption: lang("common.edit"),
            icon: "pencil",
            title: lang("common.edit"),
            type: "modifier-modal",
            mutation: "spectra.id.notes.PATCH",
            formAttribs: editFormAttribs,
            ...editSpectrum,
          },
        },
        sections: [
          {
            items: [
              {
                id: "spectrum",
                type: "spectrum",
                data: spectrum?.data,
              },
            ],
          },
          { ...participantSection },
        ],
      },
      {
        header: {
          title: lang("captions.notes"),
          buttons: {
            caption: lang("common.edit"),
            icon: "pencil",
            title: lang("spectra.edit_notes"),
            type: "modifier-modal",
            mutation: "spectra.id.notes.PATCH",
            formAttribs: notesFromAttribs,
            ...editNotes,
          },
        },
        items: notesBlockItems,
      },
      {
        header: {
          title: lang("tags.title"),
          buttons: {
            caption: lang("common.edit"),
            icon: "pencil",
            title: lang("spectra.edit_tags_list"),
            type: "modifier-modal",
            mutation: "spectra.id.tags.POST",
            formAttribs: tagsFromAttribs,
            ...editTags,
          },
        },
        items: tagsBlockItems,
      },
      {
        header: {
          title: lang("features.title"),
          buttons: {
            caption: lang("common.edit"),
            icon: "pencil",
            title: lang("spectra.edit_features_list"),
            type: "modifier-modal",
            mutation: "spectra.id.features.POST",
            formAttribs: featuresFormAttribs,
            ...editFeatures,
          },
        },
        items: featuresBlockItems,
      },
    ],
  };

  return page;
};

export default useSpectrumPage;
