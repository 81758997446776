const ADMIN = ["ADMIN"];
const EXPERT = ["EXPERT"];
const REGISTERED = [...ADMIN, ...EXPERT];
const ORGANISATION_OWNER = ["ORGANISATION_OWNER"];
const TESTER = ["TESTER"];
const STAFF = [...ORGANISATION_OWNER, ...TESTER];

const accessConfig = {
  routes: {
    features: REGISTERED,
    conclusions: REGISTERED,
    tags: REGISTERED,
    spectra: REGISTERED,
    users: ADMIN,
    organisations: ADMIN,
    staff: ORGANISATION_OWNER,
    assessments: STAFF,
    participants: STAFF,
    test: TESTER,
  },
  apiCalls: {
    "features.GET": REGISTERED,
    "features.POST": REGISTERED,
    "features.id.GET": REGISTERED,
    "features.id.PATCH": REGISTERED,
    "features.id.DELETE": REGISTERED,
    "features.id.conclusions.POST": REGISTERED,
    "conclusions.GET": REGISTERED,
    "conclusions.POST": REGISTERED,
    "conclusions.id.GET": REGISTERED,
    "conclusions.id.PATCH": REGISTERED,
    "conclusions.id.DELETE": REGISTERED,
    "conclusions.id.features.POST": REGISTERED,
    "tags.GET": REGISTERED,
    "tags.POST": REGISTERED,
    "tags.id.spectra.GET": REGISTERED,
    "spectra.GET": REGISTERED,
    "spectra.id.GET": REGISTERED,
    "spectra.id.DELETE": REGISTERED,
    "spectra.id.PATCH": REGISTERED,
    "spectra.id.features.POST": REGISTERED,
    "spectra.id.tags.POST": REGISTERED,
    "spectra.id.notes.PATCH": REGISTERED,
    "spectra.id.name.PATCH": REGISTERED,
    "users.GET": REGISTERED,
    "users.POST": REGISTERED,
    "organisations.GET": ADMIN,
    "organisations.POST": ADMIN,
    "organisations.id.users.GET": ORGANISATION_OWNER,
    "organisations.id.users.POST": ORGANISATION_OWNER,
    "organisations.id.users.id.DELETE": ORGANISATION_OWNER,
    "organisations.id.users.id.PATCH": ORGANISATION_OWNER,
    "organisations.id.participants.GET": STAFF,
    "organisations.id.participants.POST": STAFF,
    "organisations.id.assessments.GET": STAFF,
    "organisations.id.assessments.POST": TESTER,
    "organisations.id.assessments.id.GET": STAFF,
  },
};

export default accessConfig;
