import { apiSlice } from "../api/apiSlice";
import getPaginationInfo from "../../utils/getPaginationInfo";
import paramConfig from "../../config/paramConfig";

export const spectraSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpectra: builder.query({
      query: ({ page = 1, pageSize = 30, sortBy }) =>
        `/spectra?${paramConfig.PAGE_NUMBER}=${page}&${
          paramConfig.PAGE_SIZE
        }=${pageSize}${sortBy ? paramConfig.SORT_BY + "=" + sortBy : ""}`,
      transformResponse: (response, meta) => {
        const pagination = getPaginationInfo(meta);
        return { response, pagination };
      },
      providesTags: (result, error, arg) => [
        { type: "Spectrum" },
        { type: "Spectrum", id: arg?.page },
        { type: "Spectrum", id: arg?.pageSize },
        { type: "Spectrum", id: arg?.sortBy || "unsorted" },
      ],
    }),
    getSpectrum: builder.query({
      query: ({ id }) => `/spectra/${id}`,
      providesTags: (result, error, arg) => [
        { type: "Spectrum", id: arg?.page },
      ],
    }),
    postSpectrum: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/spectra`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
    deleteSpectrum: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/spectra/${params?.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
    patchSpectrum: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/spectra/${params?.id}`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
    assignSpectrumToFeature: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/spectra/${id}/features`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
    removeSpectrumFromFeature: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/spectra/${params?.spectrumId}/features/${params?.featureId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
    assignTagToSpectrum: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: `/spectra/${id}/tags`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
    patchSpectrumName: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/spectra/${params?.id}/name`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
    patchSpectrumNotes: builder.mutation({
      query: ({ params, body }) => {
        return {
          url: `/spectra/${params?.id}/notes`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
    removeTagFromSpectrum: builder.mutation({
      query: ({ params }) => {
        return {
          url: `/spectra/${params?.spectrumId}/tags/${params?.tagId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Spectrum" }],
    }),
  }),
});

export const {
  useGetSpectraQuery,
  useGetSpectrumQuery,
  usePostSpectrumMutation,
  useDeleteSpectrumMutation,
  usePatchSpectrumMutation,
  useAssignSpectrumToFeatureMutation,
  useRemoveSpectrumFromFeatureMutation,
  useAssignTagToSpectrumMutation,
  useRemoveTagFromSpectrumMutation,
  usePatchSpectrumNameMutation,
  usePatchSpectrumNotesMutation,
} = spectraSlice;
